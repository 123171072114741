.users-count{
    color: gray;
    font-size: 18px;
}

.directory-gray-space{
    position: fixed;
    left:0;
    top:0;
    width:100%;
    height: 210px;
    background-color: white;
}

.directory-blur{
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: gray;
    opacity: 0.8
}

.directory-tool{
    position:fixed;
    display: flex;
    top: 145px;
    left: 15%;
}

.add-users-btn{
    color: rgb(0,86,63);
}

.add-users-btn:hover{
    opacity: 0.8;
    cursor: pointer;
}

.user-search{
    margin-top: 4.4px;
    margin-left: 15px;
    width: 560px;
    color: rgb(80,80,80);
}

.user-search input{
    width: 87.5%;
    border: 1px solid rgb(200, 200, 200);
    background-color: white;
    padding: 9px 35px;
    font-family: "Segoe UI";
    border-radius: 2px;
    outline: none;
}

.users-search-icon{
    position: absolute;
    top: 46%;
    left: 80px;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.directory-tool-icons{
    color: rgb(80,80,80);
    margin-left: 13px;
    margin-top: 7px;
    cursor:pointer;
}

.directory-tool-icons:hover{
    opacity: 0.7
}

.user-prompt{
    width: 300px;
    height: 160px;
    background-color: white;
    position: fixed;
    top: 40%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 2px 2px #ebebeb;

}

.user-cancel{
    position: absolute;
    top: 12px;
    right: -35px;
    padding: 8px;
    border-radius: 50px;
    margin-right: 50px;
    color: rgb(50,50,50);
    background-color: rgb(235,235,235);
}

.user-cancel:hover{
    background-color: rgb(215,215,215);
    cursor: pointer;
}

.add-file{
    font-family: "Segoe UI";
    font-weight: 600;
    color: rgb(50,50,50);
    margin-top: 20px;
    margin-bottom: 30px;
}

.add-note{
    font-family: "Segoe UI";
    font-size: small;
    color:rgb(100, 100, 100);
    font-style: italic;
}

#user-upload-btn{
    text-align: center;
    padding: 10px 15px;
    width: 60%;
    outline: none;
    border-radius: 2px;
    background-color:white;
    border:none;
    font-family: "Segoe UI";
    font-size: 15px;
    color: white;
    background-color: rgb(0,86,63);
}

#user-upload-btn:hover:enabled{
    opacity: 0.8;
    cursor: pointer;
}

#user-upload-btn:disabled{
    background-color: rgba(0,86,63,0.5);
}

.delete-note{
    font-family: "Segoe UI";
    font-weight: 600;
    color: rgb(50,50,50);
    margin-top: 40px;
    margin-bottom: 30px;
}

.user-delete-btn{
    text-align: center;
    padding: 10px 15px;
    width: 45%;
    outline: none;
    border-radius: 2px;
    background-color:white;
    border:none;
    font-family: "Segoe UI";
    font-size: 15px;
    color: white;
    background-color: rgb(141,20,54);
}

.user-delete-btn:hover{
    opacity: 0.8;
    cursor: pointer;
}

.directory-table{
    text-align: 'center';
    position: absolute;
    top: 210px;
    left: 15%;
    width: 70%;
    font-family: "Segoe UI";
    border-spacing: 0px;
    border-collapse: separate;
}

.users-empty{
    position: fixed;
    font-family: "Segoe UI";
    font-size: 16px;
    top: 190px;
    left: 15%;
    font-weight: 500;
    color: rgb(80, 80, 80)
}

.user-actions{
    font-family: "Segoe UI";
    text-align: center;
    outline: none;
    cursor: pointer;
    border: none;
    padding: 5px 15px;
    margin: 0 5px;
    display: inline-flex;
    border-radius: 2px;
    background:none;
    color:rgb(50, 50, 50);
    box-shadow: 1px 1px #bebebe;
}

.user-actions:hover{
    background-color: rgb(210, 210, 210);
}

.user-action-icon{
    margin-right: 7px;
    color:rgb(50, 50, 50)
}

.directory-dropdown{
    background-color: white;
    font-family: "Segoe UI";
    margin-top: 3.3px;
    margin-left: 7px;
    color: rgb(80, 80, 80);
    font-size: 14px;
    border: none;
    width: 180px;
    border: 1px solid rgb(248,248,248);
    height: 90%;
    cursor: pointer;
}

.directory-dropdown:hover{
    border-radius: 2px;
    border: 0.2px solid gray;
    
}

.user-edit-prompt{
    width: 400px;
    height: 390px;
    background-color: white;
    position: fixed;
    top: 48%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px;
    padding-top: 35px;
    border-radius: 2px;
    box-shadow: 2px 2px #ebebeb;
}

.user-edit-prompt b{
    font-family: "Segoe UI";
    color: rgb(50,50,50)
}

.input-wrapper {
    position: relative;
    font-family: "Segoe UI"
}

.input-wrapper label{
    position: absolute;
    top: 0.2ex;
    z-index: 1;
    left: 2em;
    background-color: white;
    padding: 0 5px;
    font-size: 10px;
    color: rgb(80,80,80)
}

.input-wrapper input{
    font-family: "Segoe UI";
    border: 1px solid gray;
    border-radius: 2px;
    position: relative;
    width: 85%;
    margin: 10px;
    padding: 15px;
    color: rgb(80,80,80);
    outline: none;
}

.user-edit-btn{
    text-align: center;
    padding: 13px 15px;
    width: 93.2%;
    outline: none;
    border-radius: 2px;
    background-color:white;
    border:none;
    font-family: "Segoe UI";
    font-size: 15px;
    color: white;
    background-color: rgb(0,86,63);
    margin-top: 9px;
}

.user-edit-btn:hover{
    opacity: 0.8;
    cursor: pointer;
}
