@media print{
    @page { 
        size: auto;
        margin: 50px 0px 35px 0px ;     
      }

    .transactions-page{
        margin: 0.3in;
        font-family: "Segoe UI";
        text-align: center;
    }

    .transactions-title{
        position: absolute;
        top: -10px;
        text-align: center;
    }

    .transactions-table{
        width: 90.5%;
        position: absolute;
        top: 40px;
    }

    .print-th{
        padding: 5px 2px;
        background-color: rgb(80, 80, 80);
        color: white;
        font-size: 9px;
        font-weight: 500;
        top: 0px;
        border: none
    }
    
    .print-td{
        padding: 3px 0px;
        font-size: 9px;
    }
    
}