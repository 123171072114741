.notfound-logo{
    position:absolute;
    top: 5%;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 20%;
}

.oops{
    position: relative;
    margin-top: 23%;
    text-align: center;
    font-family: "Segoe UI";
    opacity: 1;
    color: rgb(141,20,54);
    font-size: 110px;
    margin-bottom:0 ;
}

.oops-text{
    margin-top: 0;
    position: relative;
    text-align: center;
    font-family: "Segoe UI";
    opacity: 1;
    color: rgb(80,80,80);
    font-size: 40px;
    margin-bottom:0 ;
}

.notfound-btn{
    position: absolute;
    align-items: center;
    bottom: 60px;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 10px 30px;
    background-color: rgb(0,86,63);
    color: white;
    font-family: "Segoe UI" ;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 3px;
}

.notfound-btn:hover{
    opacity: 0.8;
}