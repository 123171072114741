.upload-send{
    position: fixed;
    top: 200px;
    left: 20%;
}

#file-acceptor{
    display:none;
}

.upload-note{
    color: rgb(80,80,80);
    position: fixed;
    top: 115px;
    left: 20%;
    font-style: italic;
    font-size: 18px;
}

.upload-icon{
    color: rgb(80,80,80);
}

.upload-interface{
    text-align: center;
    width: 60%;
    height: 100px;
    border-radius: 3px;
    background-color: white;
    position:fixed;
    padding: 40px 0px;
    top: 180px;
    left: 20%;
    box-shadow: 2px 2px #ebebeb;
    cursor: pointer;
}

.upload-text{
    font-family: "Segoe UI";
    font-size: 20px;
    color: rgb(80,80,80)
}

#upload-button{
    border:none;
    outline:none;
    width: 60%;
    height: 45px;
    position:relative;
    top: 370px;
    left:20%;
    font-size: 15px;
    background-color: #00563F;
    border-radius: 3px;
    color:#fcfff6;
    font-weight: bold;
    padding:4px;
    
    font-family: "Segoe UI";
    letter-spacing: 2px;
}

#upload-button:enabled:hover{
    opacity: 0.8;
    cursor:pointer;
}

#upload-button:disabled{
    opacity: 0.5;
}

.filenames-title{
    position: fixed;
    background-color: white;
    width: 58%;
    margin-top: 0;
    padding: 10px 0px;
    font-style: italic;
    color:rgb(80, 80, 80);
    font-weight: 500;
}

.filenames{
    position: fixed;
    top: 420px;
    left: 20%;
    width: 60%;
    height: 150px;
    overflow-y: scroll;
    color:rgb(80, 80, 80);
}